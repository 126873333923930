import request from '@/utils/request'
import { _GLOBAL } from '@/api/server'
import secureStorage from '@/utils/secureStorage'

/** API SecurityType */
export function getSecurityType(listQuery) {

  // console.log(listQuery)
  var page = listQuery.page
  var limit = listQuery.limit
  var search = listQuery.search
  // console.log(limit)

  var token = secureStorage.getItem('jwt')
  if (token == null) throw 'token null'

  var start = (page - 1) * limit
  if (start < 0) start = 0

  var uri = _GLOBAL.URLBACKEND + '/eosecuritytypes?security_type_contains=' + search + '&_limit=' + limit + '&_start=' + start +'&_sort=id%3ADESC'
  var data = listQuery
  // console.log(uri)
  var response = request({
    url: uri,
    method: 'get',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
      'Authorization': `Bearer ${token}`
    },
    data
  })
  // console.log(response)
  return response
}

export function getListSecurityTypeActive() {
  var token = secureStorage.getItem('jwt')
  if (token == null) throw 'token null'

  var uri = _GLOBAL.URLBACKEND + '/eosecuritytypes?isActive=true'
  var data = ''
  // console.log(uri)
  var response = request({
    url: uri,
    method: 'get',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
      'Authorization': `Bearer ${token}`
    },
    data
  })
  // console.log(response)
  return response
}

export function getSecurityTypeByID(id) {
  // console.log(id)
  if (id <= 0) return null

  var token = secureStorage.getItem('jwt')
  if (token == null) throw 'token null'
  
  var uri = _GLOBAL.URLBACKEND + '/eosecuritytypes/' + id
  var data = ''
  // console.log(uri)
  var response = request({
    url: uri,
    method: 'get',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
      'Authorization': `Bearer ${token}`
    },
    data
  })
  // console.log(response)
  return response
}

export function insertSecurityType(data) {
  var token = secureStorage.getItem('jwt')
  if (token == null) throw 'token null'
  if (!data) return null
  // console.log(data)

  var uri = _GLOBAL.URLBACKEND + '/eosecuritytypes'
  var response = request({
    url: uri,
    method: 'post',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
      'Authorization': `Bearer ${token}`
    },
    data
  }).catch((err) => {
    return {'id': 0, 'data':'', 'status': 'Data should be a unique please recheck the data.'}
  })
  // console.log(response)
  return response
}

export function deleteSecurityType(id) {
  var token = secureStorage.getItem('jwt')
  if (token == null) throw 'token null'
  var data = ''

  var uri = _GLOBAL.URLBACKEND + '/eosecuritytypes/' + id
  var response = request({
    url: uri,
    method: 'delete',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
      'Authorization': `Bearer ${token}`
    },
    data
  })
  // console.log(response)
  return response
}

export function updateSecurityType(data, id) {
  var token = secureStorage.getItem('jwt')
  if (token == null) throw 'token null'
  if (!data) return null

  var uri = _GLOBAL.URLBACKEND + '/eosecuritytypes/' + id
  var response = request({
    url: uri,
    method: 'put',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
      'Authorization': `Bearer ${token}`
    },
    data
  })
  // console.log(response)
  return response
}

export function countSecurityType(listQuery) {
  var search = listQuery.search

  var token = secureStorage.getItem('jwt')
  if (token == null) throw 'token null'
  var data = ''
  
  var uri = _GLOBAL.URLBACKEND + '/eosecuritytypes/count?security_type_contains=' + search
  var response = request({
    url: uri,
    method: 'get',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
      'Authorization': `Bearer ${token}`
    },
    data
  })
  // console.log(response)
  return response
}

/** API SecurityType ==== */


