<template>
  <transition name="menu">
    <form-surat :is-edit="false"/>
  </transition>
</template>

<script>
import formSurat from '../components/formSurat4'

export default {
  name: 'CreateformSurat4',
  components: { formSurat }
}
</script>

